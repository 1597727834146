$light: #fff;
$primary-color: #223a66;
$secondary-color:#e12454;
$base-color:#6F8BA4;
$title-color:#223a66;
$black:#222;
$gray:#eff0f3;
$secondary-bg:#f4f9fc;
$border-color:#fafafa;
$primary-font: 'Exo', sans-serif;
$secondary-font:'Roboto', sans-serif;

$grad-color:linear-gradient(to bottom,#05a5f9,#10dab7);