.contact-form-wrap{
      .form-group{
       margin-bottom: 20px;
        .form-control{
          height:60px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
          background: #f4f9fc;
        }
      }
      .form-group-2{
        margin-bottom: 13px;
        textarea{
          height:auto;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          background: #f4f9fc;
          width: 100%;
        }
      }
}


.social-icons {
  li {
    margin:0 6px;
  }
 
  a{
    margin-right: 10px;
    font-size: 18px;
  }
}

.google-map {
    position: relative;
}

.google-map #map {
    width: 100%;
    height: 500px;
}



.mt-90{
  margin-top: 90px;
}

.contact-block{
  text-align: center;
  border:5px solid #EEF2F6;
  padding:50px 25px;
  i{
    font-size: 50px;
    margin-bottom: 15px;
    display: inline-block;
    color: $secondary-color;
  }
}