
.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],input[type="password"],input[type="text"],input[type="tel"]{
  box-shadow:none;
  height:45px;
  outline: none;
  font-size:14px;
  &:focus {
    box-shadow: none;
    border:1px solid $primary-color;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow:none;
    border:1px solid $primary-color;
  }
}

//

.py-7{
  padding: 7rem 0px;
}

// Button Style

.btn{
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing:.5px;
  padding: .75rem 2rem;
  font-family: $primary-font;
  text-transform: uppercase;
  border-radius:5px;
  border:2px solid transparent;
  // box-shadow: 0 11px 22px rgba(34, 34, 34, 0.2);
  -webkit-transition: all .35s ease;
     -moz-transition: all .35s ease;
      -ms-transition: all .35s ease;
       -o-transition: all .35s ease;
          transition: all .35s ease;

   &.btn-icon {
    i {
        border-left:1px solid rgba(255,255,255,.09);
        padding-left: 15px;    
      }
  }

  &:focus{
    outline: 0px;
    box-shadow: none;
  }
}

.btn-main {
  background: $primary-color;
  color: $light;
  border-color:$primary-color;
 
  &:hover {
    background:$secondary-color;
    border-color:$secondary-color;
    color: $light;
  }
}
.btn-main-2 {
  background: $secondary-color;
  color: $light;
  border-color:$secondary-color;
 
  &:hover {
    background: $primary-color;
    color: $light;
    border-color:$primary-color;
  }
}


.btn-solid-border {
  border:2px solid $primary-color;
  background:transparent;
  color:$primary-color;
  
  &:hover {
    border:2px solid $primary-color;
    color:$light;
    background:$primary-color;
    &.btn-icon {
      i {
          border-left:1px solid rgba(255,255,255,.09);
        }
    }
  }
  &.btn-icon {
    i {
        border-left:1px solid rgba(0,0,0,.09);
      }
  }

}


.btn-transparent {
  background:transparent;
  color:$black;
  border-color:$base-color;
  &:hover {
    background:$base-color;
    color:$light;
  }
}
.btn-white{
  background: $light;
  border-color:$light;
  color: $black;

  &:hover{
    background: $primary-color;
    color: $light;
    border-color:$primary-color;
  }
}

.btn-solid-white{
  border-color:$light;
  color: $light;

  &:hover{
    background: $light;
    color: $black;
  }
}


.btn-round {
  border-radius:4px;
}
.btn-round-full {
  border-radius:50px;
}


.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}


// Background

.bg-gray {
  background:$gray;
}
.bg-primary {
  background:$primary-color;
}
.bg-primary-dark {
  background:darken($primary-color, 10%);
}
.bg-primary-darker {
  background:darken($primary-color, 20%);
}

.bg-dark {
  background:$black;
}


.bg-gradient{
    background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
    background-repeat: repeat-x;
}


//  Section Title
.section {
  padding:100px 0;
}
.section-sm {
  padding:70px 0;
}

.section-bottom{
  padding-bottom: 100px;
}

.subtitle {
  color: $primary-color;
  font-size: 14px;
  letter-spacing: 1px;
}




.overlay {
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background:$primary-color;
  }
}


.overly-2 {
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }
}



.text-sm{
  font-size: 14px;
}
.text-md{
  font-size: 2.25rem;
}
.text-lg{
  font-size:3.75rem; 
}

.no-spacing{
  letter-spacing: 0px
}


/* Links */
a {
  color: $black;
  text-decoration: none;
  -webkit-transition: all .35s ease;
     -moz-transition: all .35s ease;
      -ms-transition: all .35s ease;
       -o-transition: all .35s ease;
          transition: all .35s ease;
}

a:focus, a:hover {
  color: $secondary-color;
  text-decoration: none;
}

a:focus {
  outline: none;
}


.content-title {
  font-size: 40px;
  line-height: 50px;
}



.page-title{
  padding:120px 0px 70px 0px;
  position: relative;
  
  .block{
    h1{
      color:$light;
    }
    p{
      color:$light;
    }
  }

  .breadcumb-nav{
    margin-top: 60px;
    padding-top: 30px;
    border-top:1px solid rgba(255,255,255,0.06);
  }
}



.slick-slide {
  &:focus, a {
    outline: none;
  }
}


@include mobile{
   h2, .h2 {
      font-size: 1.3rem;
      line-height: 36px;
  }
}

.title-color{
  color: $title-color;
}


.secondary-bg{
  background: $title-color;;
}


.section-title {
  margin-bottom: 70px;
  h2{
    color: $title-color;
  }
}


.text-lg{
  font-size:50px;
}
.gray-bg{
  background:#f4f9fc;
}

@include mobile{
  .text-lg{
    font-size: 28px;
  }
}
@include mobile-xs{
  .text-lg{
    font-size: 28px;
  }
}