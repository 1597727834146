.service{
	padding-top:180px;

	.service-item{
		background: $light;
		padding: 30px;
		border-radius:5px;
	}

	.icon{
		float: left;
		margin-bottom: 10px;
	}
	i{
		color: $secondary-color;
	}
	h4{
		padding-left: 20px;
	}

	.content{
		clear: both;
	}
}

//  Service-2


.service-block{
	padding: 20px;
	margin-top: 40px;
	border:1px solid rgba(0,0,0,0.03);
	box-shadow: 0 0 38px rgba(21,40,82,.07);

	img{
		width: 100%;
		margin-top: -60px;
		border:5px solid $light;
	}

}

